<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#inox-gradient-4);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }
      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>
    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 1630.54"
                    :y1="doorTopHeight1 + 7495.3"
                    :x2="doorLeftWidth1 + 1630.54"
                    :y2="doorTopHeight1 + 7425.3"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1672.58"
                    :y1="doorTopHeight1 + 7495.3"
                    :x2="doorLeftWidth1 + 1672.58"
                    :y2="doorTopHeight1 + 7425.3"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 48.88"
                    :y1="doorTopHeight1 + 159.73"
                    :x2="doorLeftWidth1 + 48.88"
                    :y2="doorTopHeight1 + 48.66"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 48.88"
                    :y1="doorTopHeight1 + 155.07"
                    :x2="doorLeftWidth1 + 48.88"
                    :y2="doorTopHeight1 + 53.33"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 90.93"
                    :y1="doorTopHeight1 + 159.73"
                    :x2="doorLeftWidth1 + 90.93"
                    :y2="doorTopHeight1 + 48.66"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 90.93"
                    :y1="doorTopHeight1 + 155.07"
                    :x2="doorLeftWidth1 + 90.93"
                    :y2="doorTopHeight1 + 53.33"
                    xlink:href="#glass-gradient"/>
    <!-- Molding Gradient BEGIN -->
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <!-- Molding Gradient END -->
    <g id="C13">
      <path id="inox"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 1616.66},${doorTopHeight1 + 7425.3}
            l-0.23,70
            h70.23
            v-70
            h-70
            Z
            m22.74,65.34
            h-17.73
            V${doorTopHeight1 + 7430}
            h59.73
            v60.67
            Z
            `"
            transform="translate(-1581.65 -7393)"/>
      <path id="inox-2"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 1616.66},${doorTopHeight1 + 7425.3}
            l-0.23,70
            h70.23
            v-70
            h-70
            Z
            m22.74,65.34
            h-17.73
            V${doorTopHeight1 + 7430}
            h59.73
            v60.67
            Z
            `"
            transform="translate(-1581.65 -7313)"/>
      <path id="inox-3"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 1616.66},${doorTopHeight1 + 7425.3}
            l-0.23,70
            h70.23
            v-70
            h-70
            Z
            m22.74,65.34
            h-17.73
            V${doorTopHeight1 + 7430}
            h59.73
            v60.67
            Z
            `"
            transform="translate(-1581.65 -7233)"/>
      <!-- Molding BEGIN -->
      <g id="Moldings" v-if="showMolding">
        <g id="Molding-1" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 98.03}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 98.03}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 98.03}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 40.18}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 98.03}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 40.18}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 35.74}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 102.47}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 98.03}
            `"/>
        </g>
        <g id="Molding-3" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 200.67}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 258.52}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 200.67}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 258.52}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 200.67}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 258.52}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 200.67}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 258.52}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 200.67}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 196.24}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 262.96}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 258.52}
            `"/>
        </g>
        <g id="Molding-2" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 120.5}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 178.35}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 120.5}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 178.35}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 120.5}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 178.35}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 39.82} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 34.78} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 39.65} ${doorTopHeight1 + 120.5}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 178.35}
              ${doorLeftWidth1 + 100.44} ${doorTopHeight1 + 120.5}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 116.07}
              ${doorLeftWidth1 + 105.48} ${doorTopHeight1 + 182.79}
              ${doorLeftWidth1 + 100.61} ${doorTopHeight1 + 178.35}
            `"/>
        </g>
      </g>
      <!-- Molding END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
